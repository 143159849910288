<script lang="ts">
import { defineComponent, h } from 'vue';

import useSpelling from '../composables/useSpelling.ts';
import { escapeHtml } from '../src/helpers.ts';
import { safeInlineMarkdown } from '../src/simpleMarkdown.ts';

export default defineComponent({
    props: {
        text: { default: '', type: String },
        escape: { type: Boolean },
        markdown: { type: Boolean },
    },
    setup() {
        const { handleSpelling } = useSpelling();
        return {
            handleSpelling,
        };
    },
    render() {
        let text = this.text;
        text = text.replace('<script', '');
        if (this.escape) {
            text = escapeHtml(text);
        }
        if (this.markdown) {
            text = safeInlineMarkdown(text);
        }

        return h('span', { ...this.$attrs, innerHTML: this.handleSpelling(text) });
    },
});
</script>
